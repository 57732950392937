import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import Choices from "choices.js";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "sidebar_table",
    "search", "department_ids",
    "mapping_dropdown", "mapping_checkbox",
    "sidebar_search", "sidebar_department_ids",
    "sidebar_current_sort", "sidebar_sort", "sidebar_current_filter", "sidebar_filter",
    "form_role_select", "form_role_label_star", "form_salary_currency_text",
    "form_email_label_star", "form_email_input", "project_ids", "sidebar_project_ids"
  ]

  connect() {
    // Main table
    if (this.hasIndex_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createIndexTable(content_url);
    }

    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }
  }

  //
  // Actions
  //
  openNewEmployeeModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  openEditEmployeeModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  onSystemAccessChange(event) {
    let role_choice = this.form_role_selectTarget.choices;
    let required_star = this.form_role_label_starTarget
    let email_field = this.form_email_inputTarget;
    let email_label_star = this.form_email_label_starTarget;

    if (event.target.checked) {
      role_choice.enable();
      required_star.innerText = "*";

      email_field.required = true;
      email_label_star.innerText = "*";
      email_field.disabled = false;
    } else {
      role_choice.disable();
      required_star.innerText = "";

      email_field.required = false;
      email_label_star.innerText = "";
      email_field.disabled = true;
    }
  }

  onUpdateCurrencyFields(event) {
    let currency = event.target.value;
    this.form_salary_currency_textTargets.forEach(el => {
      el.innerText = currency;
    })
  }


  filterTable() {
    this.setFilterToTable();
  }

  setSidebarFilter() {
    this.setSidebarFilterToTable();
  }

  setSidebarCurrentFilter() {
    let department_texts = ""

    if (this.hasSidebar_department_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_department_idsTarget);
      const fieldContainer = this.sidebar_department_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) department_texts = column_name + ": &nbsp;" + items
    }

    const current_filter = this.sidebar_current_filterTarget;
    current_filter.innerHTML = department_texts;
  }


  // Main table filters
  setFilterToTable() {
    let filters = this.getPageFilters();

    if (filters.length) {
      let table = this.index_tableTarget.tabulator;
      table.setFilter(filters);

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  // Sidebar Table Filters
  setSidebarFilterToTable() {
    let filters = this.getSidebarPageFilters();

    if (filters.length) {
      let sidebar_table = this.sidebar_tableTarget.tabulator;

      sidebar_table.setFilter(filters);
      this.setSidebarCurrentFilter();
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDepartment_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.department_idsTarget)
      })
    }

    if (this.hasProject_idsTarget) {
      filters.push({
        field: "project_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.project_idsTarget)
      })
    }

    return filters;
  }

  getSidebarPageFilters() {
    let filters = []

    if (this.hasSidebar_searchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.sidebar_searchTarget.value
      })
    }

    if (this.hasSidebar_department_idsTarget) {
      filters.push({
        field: "department_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_department_idsTarget)
      })
    }

    if (this.hasSidebar_project_idsTarget) {
      filters.push({
        field: "project_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_project_idsTarget)
      })
    }

    return filters;
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    // change sort dir
    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    sidebar_table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
      // if (sort_target.querySelector("i")) sort_target.querySelector("i").remove();
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this.getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // gen icon html
  getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir == "asc") up_or_down = "down"
    const html = column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>"
    return html;
  }

  // Create main table
  async createIndexTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.index_tableTarget;
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const table_id = this.data.get("table-id");

    let table_props = {
      persistenceID: "employees_table" + table_id,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      // tree table
      dataTree: true,
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.user__status) {
          row.getElement().classList.add("row-" + data.user__status)
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  // create sidebar


  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    const per_page = this.data.get("per-page");
    const table_target = this.sidebar_tableTarget;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "employees_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getSidebarPageFilters(),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.status) row.getElement().classList.add("row-" + data.status)
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          // row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    this.setSidebarCurrentFilter();
  }

  convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }

  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  getSelectCollectionTexts(target) {
    return Array.apply(null, target.selectedOptions).map(function (el) { return el.text; });
  }
}